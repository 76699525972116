import {
    FieldHelperProps,
    FieldHookConfig,
    FieldInputProps,
    FieldMetaProps,
    FormikContextType,
    FormikValues,
} from 'formik';
import { ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';

interface CandidateApplicationComponentDTO {
    componentType:
        | 'single_value_component'
        | 'multi_value_component'
        | 'no_value_component';

    id?: string;
    label?: string;
}

const isDynamicFieldValue = component =>
    component && component.id != null && component.componentRequestType != null;

type CandidateApplicationComponent = CandidateApplicationComponentDTO &
    SingleValueComponent &
    MultiValueComponent;

interface ControlEvent {
    onExactValue?: {
        values: Array<string | number | boolean>;
        eventHandler: {
            componentIdEnabler: Array<string>;
            readyOnlyComponents?: Array<any>;
        };
    };
}

interface Constraints {
    min?: number;
    max?: number;
    regexes?: { expression: string; message?: string };
    specificDateId?: string;
    minDaysAfterSpecificDate?: number;
}

interface ControlBase {
    id: string;
    label: string;
    validations?: Array<string>;
    requestType: 'single_value' | 'multi_value' | 'no_value';
    componentType: ControlType;
    enabled: true | false;
    events?: Array<ControlEvent>;
    required?: boolean;
    sectionId?: string;
    dataTestId?: string;
    uniqueIdentifier?: string;
    disabled?: boolean;
    constraints?: Constraints[];
    className?: string;
    type?: any;
}

enum ControlType {
    INPUT = 'INPUT',
    TEXT_AREA = 'TEXT_AREA',
    TITLE = 'TITLE',
    COLLECTION = 'COLLECTION',
    MULTI_CHECKBOX = 'MULTI_CHECKBOX',
    OUTPUT = 'OUTPUT',
    UPLOAD = 'FILE_UPLOADER',
    DOCUMENT_UPLOADER = 'DOCUMENT_UPLOADER',
    SWITCH = 'TOGGLE_SWITCH',
    MULTI_INPUT = 'MULTI_INPUT',
    MULTI_SELECT = 'MULTI_SELECT',
}

enum InputType {
    'TEXT' = 'text',
    'NUMBER' = 'number',
    'DATE_ONLY' = 'dateOnly',
    'TIME_ONLY' = 'timeOnly',
}

enum OutputType {
    'SEPARATOR' = 'SEPARATOR',
    'TITLE' = 'TITLE',
}

enum CollectionItemType {
    'CHECKBOX' = 'checkbox',
    'RADIO' = 'radio',
    'DROPDOWN' = 'dropdown',
    // remove SEARCHSELECT after make sure no other places use it
    'SEARCHSELECT' = 'searchable_select',
    'SEARCHDROPDOWN' = 'searchable_dropdown',
}

enum MultiSelectType {
    'DROPDOWN' = 'dropdown',
}

type InputControlBase = ControlBase & {
    componentType: ControlType.INPUT | ControlType.MULTI_INPUT;
    placeholder?: string;
    type?: InputType;
    action?: string;
    linkedTo?: string;
    inputAddon?: string;
    minDate?: string;
    maxDate?: string;
    readonly?: boolean;
};

type TextEditorControlBase = ControlBase & {
    componentType: ControlType.TEXT_AREA;
    placeholder?: string;
    hasRichEditor?: boolean;
    rows?: number;
    cols?: number;
};

type MultiCheckboxControlBase = ControlBase & {
    componentType: ControlType.MULTI_CHECKBOX;
    items: CollectionControlBase['items'];
    field?: FieldInputProps<any>;
};

type FileControlBase = ControlBase & {
    componentType: ControlType.UPLOAD;
    mimeTypes?: Array<string>;
    type?: 'file' | 'image';
    maxSizeByMega?: number;
    disabled?: boolean;
    multiple?: boolean;
    download?: {
        url?: string;
        label?: string;
    };
};

type DocumentControlBase = ControlBase & {
    componentType: ControlType.DOCUMENT_UPLOADER;
    mimeTypes?: Array<string>;
    type?: 'file' | 'image';
    maxSizeByMega?: number;
    disabled?: boolean;
    multiple?: boolean;
    download?: {
        url?: string;
        label?: string;
    };
    filesCount?: number;
    uploaderTitle?: string;
    uploaderDescription?: string;
};

type MultiSelectControlBase = ControlBase & {
    componentType: ControlType.MULTI_SELECT;
    readonly?: boolean;
    placeholder?: string;
    type?: MultiSelectType;
    options?: string[];
    dependOnComponentId?: {
        endDateComponent?: string;
        startDateComponent?: string;
    };
    dependOnComponentUniqueIdentifier?: {
        endDateComponent?: string;
        startDateComponent?: string;
    };
};

type SwitchControlBase = ControlBase & {
    componentType: ControlType.SWITCH;
    truthyLabel?: string;
    falsyLabel?: string;
};

interface CollectionItem {
    value: string;
    label?: string;
    enabled?: boolean;
}

type CollectionControlBase = ControlBase & {
    componentType: ControlType.COLLECTION;
    placeholder?: string;
    items?: Array<CollectionItem>;
    type?: CollectionItemType;
    disabled?: boolean;
    multiple?: boolean;
    customValue?: string;
    loading?: boolean;
};

type OutputControlBase = ControlBase & {
    componentType: ControlType.OUTPUT;
    title?: string;
    text: string;
    type?: string;
};

type ControlTypeBase =
    | InputControlBase
    | TextEditorControlBase
    | MultiCheckboxControlBase
    | DocumentControlBase
    | FileControlBase
    | MultiSelectControlBase
    | SwitchControlBase
    | CollectionControlBase
    | OutputControlBase;

enum ComponentRequestType {
    SingleValue = 'single_value',
    MultiValue = 'multi_value',
    FileRequest = 'file_request',
    DocumentUploader = 'document_uploader',
    NoValue = 'no_value',
}

interface FieldValueBase {
    id: string;
    componentRequestType: ComponentRequestType;
    value?: string | number | boolean | (string | number | boolean)[];
}

interface SingleValueComponent extends FieldValueBase {
    value: string | number | boolean | (string | number | boolean)[];
}
interface MultiValueComponent extends FieldValueBase {
    values: string | number | boolean | (string | number | boolean)[];
}

type NoValueComponent = {
    id: string;
    componentRequestType: ComponentRequestType.NoValue;
};

type FieldValue = SingleValueComponent | MultiValueComponent | NoValueComponent;

interface DynamicValues extends FormikValues {
    [id: string]: FieldValue;
}

interface OnboardingErrors {
    [key: string]: OptionalObjectSchema<
        ObjectShape,
        Record<string, unknown>,
        TypeOfShape<ObjectShape>
    >;
}

interface UseField {
    <Val = any>(propsOrFieldName: string | FieldHookConfig<Val>): [
        FieldInputProps<Val>,
        FieldMetaProps<Val>,
        FieldHelperProps<Val>
    ];
}
interface UseFormikContext {
    <Values>(): FormikContextType<Values>;
}

interface SchemaComponent extends ControlBase {
    value?: string | boolean | number | (string | number | boolean)[];
    items: Array<CollectionItem>;
    uniqueIdentifier?: string;
    type: InputType | CollectionItemType;
    values?: (string | number | boolean)[];
}

export type {
    ControlBase,
    InputControlBase,
    TextEditorControlBase,
    CollectionControlBase,
    ControlTypeBase,
    MultiCheckboxControlBase,
    FileControlBase,
    DocumentControlBase,
    MultiSelectControlBase,
    SwitchControlBase,
    OutputControlBase,
    CandidateApplicationComponentDTO,
    CandidateApplicationComponent,
    CollectionItem,
    FieldValueBase,
    SingleValueComponent,
    MultiValueComponent,
    NoValueComponent,
    FieldValue,
    DynamicValues,
    OnboardingErrors,
    UseField,
    UseFormikContext,
    SchemaComponent,
};

export {
    ControlType,
    InputType,
    OutputType,
    CollectionItemType,
    MultiSelectType,
    ComponentRequestType,
    isDynamicFieldValue,
};
