import React, { FC, useCallback } from 'react';

import {
    CollectionControlBase,
    ControlType,
    ControlTypeBase,
    DocumentControlBase,
    DynamicValues,
    FileControlBase,
    InputControlBase,
    MultiCheckboxControlBase,
    MultiSelectControlBase,
    OutputControlBase,
    SwitchControlBase,
    TextEditorControlBase,
    UseField,
    UseFormikContext,
} from 'types/control-form';

import { CollectionControl } from './controls/collection';
import { DocumentUploadControl } from './controls/document-upload';
import { InputControl } from './controls/input';
import { MultiCheckboxControl } from './controls/multi-checkbox';
import { MultiInputControl } from './controls/multi-input';
import { MultiSelectControl } from './controls/multi-select';
import { OutputControl } from './controls/output';
import { SwitchControl } from './controls/switch';
import { TextAreaControl } from './controls/text-area';
import { UnsupportedField } from './controls/unsupported-field';
import { UploadControl } from './controls/upload';

type ControlProps = ControlTypeBase & {
    onEnablerValueChanged?: (itemId: string, toEnable: boolean) => void;
    onCustomChange?: any;
    changeReadOnlyStatus?: any;
    useFormikContext?: UseFormikContext;
    useField?: UseField;
    fileUpload?: (files: File[]) => Promise<unknown[]>;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RenderControl: FC<ControlProps> = props => {
    const {
        componentType,
        id,
        events,
        onEnablerValueChanged,
        onCustomChange,
        changeReadOnlyStatus = () => {},
        useFormikContext,
        useField,
    } = props;

    const [field] = useField(id);

    const { setFieldValue, setFieldTouched } =
        useFormikContext<DynamicValues>();

    const { value } = field;

    const setEnabledFieldValue = useCallback(
        (requestType: string, fieldValue: string | boolean | number | Date) => {
            setFieldValue(id, {
                id,
                componentRequestType: requestType,
                value: fieldValue,
            });
        },
        [id, setFieldValue]
    );

    const handleTextInputBlur = useCallback(() => {
        setFieldTouched(id);
        setEnabledFieldValue('single_value', field.value.value.trim());
    }, [field, id, setEnabledFieldValue, setFieldTouched]);

    const changeComponentsStatus = useCallback(
        (updatedStatusesforComponents: { [key: string]: true | false }) => {
            Object.keys(updatedStatusesforComponents).forEach(componentId => {
                onEnablerValueChanged(
                    componentId,
                    updatedStatusesforComponents[componentId]
                );
            });
        },
        [onEnablerValueChanged]
    );

    const setEnablerValue = useCallback(
        (updatedValue: boolean | string) => {
            if (value?.value !== updatedValue) {
                setEnabledFieldValue('single_value', updatedValue);
            }

            if (onCustomChange) {
                onCustomChange(updatedValue);
            }

            const updatedComponentsStatus = {};

            const eventIncludesUpdatedValue = event => {
                changeReadOnlyStatus(
                    event.onExactValue.eventHandler.readyOnlyComponents
                );

                event.onExactValue.eventHandler.componentIdEnabler.forEach(
                    componentId => {
                        updatedComponentsStatus[componentId] = true;
                    }
                );
            };

            const eventNotIncludesUpdatedValue = event => {
                event.onExactValue.eventHandler.componentIdEnabler.forEach(
                    componentId => {
                        if (!updatedComponentsStatus[componentId]) {
                            updatedComponentsStatus[componentId] = false;
                        }
                    }
                );
            };

            events?.forEach(event => {
                let updatedEvent = event;

                if (
                    updatedEvent.onExactValue?.values?.includes(
                        updatedValue.toString()
                    )
                ) {
                    eventIncludesUpdatedValue(updatedEvent);
                } else {
                    eventNotIncludesUpdatedValue(updatedEvent);
                }
            });

            changeComponentsStatus(updatedComponentsStatus);
        },
        [
            value,
            onCustomChange,
            events,
            changeComponentsStatus,
            setEnabledFieldValue,
            changeReadOnlyStatus,
        ]
    );

    switch (componentType) {
        case ControlType.INPUT: {
            return (
                <InputControl
                    componentEnabler={setEnabledFieldValue}
                    onBlur={handleTextInputBlur}
                    dataTestId="input-control"
                    {...(props as InputControlBase)}
                />
            );
        }

        case ControlType.MULTI_INPUT: {
            return (
                <MultiInputControl
                    onBlur={handleTextInputBlur}
                    dataTestId="multiinput-control"
                    {...(props as InputControlBase)}
                />
            );
        }

        case ControlType.TEXT_AREA: {
            return (
                <TextAreaControl
                    componentEnabler={setEnabledFieldValue}
                    onBlur={handleTextInputBlur}
                    dataTestId="text-area-control"
                    {...(props as TextEditorControlBase)}
                />
            );
        }

        case ControlType.COLLECTION: {
            return (
                <CollectionControl
                    setEnablerValue={setEnablerValue}
                    setEnabledFieldValue={setEnabledFieldValue}
                    dataTestId="collection-control"
                    {...(props as CollectionControlBase)}
                />
            );
        }

        case ControlType.SWITCH: {
            return (
                <SwitchControl
                    setEnablerValue={setEnablerValue}
                    dataTestId="toggle-switch-control"
                    {...(props as SwitchControlBase)}
                />
            );
        }

        case ControlType.MULTI_CHECKBOX: {
            return (
                <MultiCheckboxControl
                    dataTestId="multi-checkbox-control"
                    {...(props as MultiCheckboxControlBase)}
                />
            );
        }

        case ControlType.UPLOAD: {
            return (
                <UploadControl
                    dataTestId="file-uploader-control"
                    {...(props as FileControlBase)}
                />
            );
        }

        case ControlType.DOCUMENT_UPLOADER: {
            return (
                <DocumentUploadControl
                    dataTestId="document-uploader-control"
                    {...(props as DocumentControlBase)}
                />
            );
        }

        case ControlType.MULTI_SELECT: {
            return (
                <MultiSelectControl
                    dataTestId="multi-select-control"
                    {...(props as MultiSelectControlBase)}
                />
            );
        }

        case ControlType.OUTPUT: {
            return (
                <OutputControl
                    dataTestId="output-control"
                    {...(props as never as OutputControlBase)}
                />
            );
        }

        default:
            return <UnsupportedField key={id} />;
    }
};
